<template>
  <a-row>
    <a-col
      :sm="24"
      :md="8"
      v-for="(item, index) in checkedItems"
      :key="item[valueKey] || index"
    >
      <CheckboxComponent
        v-model="item.checked"
        :label="item[labelKey]"
        :key="item[valueKey] || index"
        :onChange="(e) => onChange({ ...item, checked: e.target.checked })"
        v-bind="$attrs.disabled ? { disabled: $attrs.disabled } : {}"
      />
    </a-col>
  </a-row>
</template>

<script>
import CheckboxComponent from "./core/VeeValidateForm/Checkbox.vue";
export default {
  components: {
    CheckboxComponent,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    labelKey: {
      type: String,
      default: "name",
    },
    valueKey: {
      type: String,
      default: "id",
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    checkedItems() {
      return this.data.map((item) => ({
        ...item,
        checked: !!this.value.find(
          (value) => value[this.valueKey] === item[this.valueKey]
        ),
      }));
    },
  },
  methods: {
    onChange(item) {
      const index = this.value.findIndex(
        (value) => value[this.valueKey] === item[this.valueKey]
      );
      if (item.checked) {
        if (index === -1) {
          this.value.push(item);
        }
      } else {
        if (index !== -1) {
          this.value.splice(index, 1);
        }
      }
    },
  },
  watch: {
    value(newVal = []) {
      this.$emit("input", newVal);
      if (this.$attrs.onChange && typeof this.$attrs.onChange === "function") {
        this.$attrs.onChange(newVal);
      }
    },
  },
};
</script>
