var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"admins-form"},[_c('a-spin',{attrs:{"spinning":_vm.loading,"tip":_vm.labels.form.loading}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ validate }){return _c('a-form',{attrs:{"model":_vm.form,"label-width":80},on:{"submit":function($event){$event.preventDefault();validate().then(_vm.handleSubmit)}}},[_c('a-row',{style:({ marginBottom: '15px' }),attrs:{"type":"flex","justify":"space-between","gutter":16}},[_c('a-col',{attrs:{"xs":24,"md":12}},[_c('h2',{staticClass:"form-title"},[_c('a-icon',{staticClass:"form-icon",attrs:{"type":_vm.isEdit ? 'edit' : 'user-add'}}),_c('span',[_vm._v(_vm._s(_vm.isEdit ? _vm.labels.form.titleEdit : _vm.labels.form.titleAdd))])],1)]),_c('a-col',{attrs:{"xs":24,"md":12}},[_c('a-row',{style:({
                alignItems: 'flex-end',
                marginBottom: '0px',
              }),attrs:{"type":"flex","justify":"end","gutter":16}},[_c('a-col',[_c('a-button-group',[_c('a-button',{attrs:{"type":"danger","icon":"arrow-left","ghost":""},on:{"click":_vm.handleCancel}},[_c('span',[_vm._v(_vm._s(_vm.labels.form.goBack))])]),(_vm.canEdit)?[_c('a-button',{attrs:{"type":"primary","ghost":"","loading":_vm.submitting},on:{"click":function($event){validate().then(() => _vm.handleSubmit(true))}}},[_c('span',[_vm._v(_vm._s(_vm.labels.form.saveAndClose))])]),_c('a-button',{attrs:{"type":"primary","loading":_vm.submitting},on:{"click":function($event){validate().then(_vm.handleSubmit)}}},[_c('span',[_vm._v(_vm._s(_vm.labels.form.save))])])]:_vm._e()],2)],1)],1)],1)],1),_c('a-row',{attrs:{"type":"flex","justify":"center"}},[_c('a-col',{attrs:{"sm":24}},[_c('a-card',[_c('a-row',{attrs:{"type":"flex","justify":"space-between","gutter":24}},[_c('a-col',{attrs:{"sm":24,"md":12}},[_c('div',{style:({
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    })},[_c('UploadAvatar',{attrs:{"accept":"image/png, image/jpeg","width":128,"height":128,"borderRadius":"50%","disabled":!_vm.canEdit},model:{value:(_vm.form.avatar),callback:function ($$v) {_vm.$set(_vm.form, "avatar", $$v)},expression:"form.avatar"}}),_c('SwitchInput',{attrs:{"label":_vm.labels.form.status,"disabled":!_vm.canEdit},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1),_c('Input',{attrs:{"label":_vm.labels.form.name.label,"rules":"required","placeholder":_vm.labels.form.name.placeholder,"readOnly":!_vm.canEdit},on:{"input":function($event){return _vm.onlyAlfa($event, 'name')}},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('Input',{attrs:{"label":_vm.labels.form.email.label,"rules":"required|email","placeholder":_vm.labels.form.email.placeholder,"readOnly":!_vm.canEdit},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),(_vm.canEdit)?_c('Input',{attrs:{"type":"password","label":_vm.labels.form.password.label,"rules":`${
                      _vm.isEdit ? '' : 'required|'
                    }min:6|validatePassword`,"placeholder":_vm.labels.form.password.placeholder,"readOnly":!_vm.canEdit},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}):_vm._e()],1),_c('a-col',{attrs:{"sm":24,"md":12}},[_c('a-card',[_c('a-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',{attrs:{"span":24}},[_c('h3',{staticClass:"card-title"},[_c('a-icon',{staticClass:"card-icon",attrs:{"type":"team"}}),_c('span',{style:({ marginLeft: '5px' })},[_vm._v(_vm._s(_vm.labels.form.roles))])],1),_c('RolesComponent',{attrs:{"data":_vm.roles,"labelKey":'description',"valueKey":'id',"disabled":!_vm.canEdit},model:{value:(_vm.form.roles),callback:function ($$v) {_vm.$set(_vm.form, "roles", $$v)},expression:"form.roles"}})],1)],1)],1)],1)],1)],1)],1)],1)],1)}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }