<template>
  <div class="admins-form">
    <a-spin :spinning="loading" :tip="labels.form.loading">
      <ValidationObserver ref="observer">
        <a-form
          :model="form"
          :label-width="80"
          slot-scope="{ validate }"
          @submit.prevent="validate().then(handleSubmit)"
        >
          <a-row
            type="flex"
            justify="space-between"
            :gutter="16"
            :style="{ marginBottom: '15px' }"
          >
            <!-- Title -->
            <a-col :xs="24" :md="12">
              <h2 class="form-title">
                <a-icon
                  :type="isEdit ? 'edit' : 'user-add'"
                  class="form-icon"
                />
                <span>{{
                  isEdit ? labels.form.titleEdit : labels.form.titleAdd
                }}</span>
              </h2>
            </a-col>
            <!-- Actions -->
            <a-col :xs="24" :md="12">
              <a-row
                type="flex"
                justify="end"
                :gutter="16"
                :style="{
                  alignItems: 'flex-end',
                  marginBottom: '0px',
                }"
              >
                <a-col>
                  <a-button-group>
                    <a-button
                      type="danger"
                      icon="arrow-left"
                      ghost
                      @click="handleCancel"
                    >
                      <span>{{ labels.form.goBack }}</span>
                    </a-button>
                    <template v-if="canEdit">
                      <a-button
                        type="primary"
                        ghost
                        :loading="submitting"
                        @click="validate().then(() => handleSubmit(true))"
                      >
                        <span>{{ labels.form.saveAndClose }}</span>
                      </a-button>
                      <a-button
                        type="primary"
                        :loading="submitting"
                        @click="validate().then(handleSubmit)"
                      >
                        <span>{{ labels.form.save }}</span>
                      </a-button>
                    </template>
                  </a-button-group>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <a-row type="flex" justify="center">
            <a-col :sm="24">
              <a-card>
                <a-row type="flex" justify="space-between" :gutter="24">
                  <a-col :sm="24" :md="12">
                    <div
                      :style="{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }"
                    >
                      <UploadAvatar
                        v-model="form.avatar"
                        accept="image/png, image/jpeg"
                        :width="128"
                        :height="128"
                        borderRadius="50%"
                        :disabled="!canEdit"
                      />

                      <SwitchInput
                        v-model="form.status"
                        :label="labels.form.status"
                        :disabled="!canEdit"
                      />
                    </div>

                    <Input
                      v-model="form.name"
                      :label="labels.form.name.label"
                      rules="required"
                      :placeholder="labels.form.name.placeholder"
                      @input="onlyAlfa($event, 'name')"
                      :readOnly="!canEdit"
                    />

                    <Input
                      v-model="form.email"
                      :label="labels.form.email.label"
                      rules="required|email"
                      :placeholder="labels.form.email.placeholder"
                      :readOnly="!canEdit"
                    />

                    <Input
                      v-if="canEdit"
                      v-model="form.password"
                      type="password"
                      :label="labels.form.password.label"
                      :rules="`${
                        isEdit ? '' : 'required|'
                      }min:6|validatePassword`"
                      :placeholder="labels.form.password.placeholder"
                      :readOnly="!canEdit"
                    />
                  </a-col>

                  <a-col :sm="24" :md="12">
                    <a-card>
                      <!-- User Roles -->
                      <a-row type="flex" justify="space-between">
                        <a-col :span="24">
                          <h3 class="card-title">
                            <a-icon type="team" class="card-icon" />
                            <span :style="{ marginLeft: '5px' }">{{
                              labels.form.roles
                            }}</span>
                          </h3>
                          <RolesComponent
                            :data="roles"
                            v-model="form.roles"
                            :labelKey="'description'"
                            :valueKey="'id'"
                            :disabled="!canEdit"
                          />
                        </a-col>
                      </a-row>
                    </a-card>
                  </a-col>
                </a-row>
              </a-card>
            </a-col>
          </a-row>
        </a-form>
      </ValidationObserver>
    </a-spin>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import labels from "@/utils/labels";
import Input from "../../../components/core/VeeValidateForm/Input.vue";
import SwitchInput from "../../../components/core/VeeValidateForm/Switch.vue";
import UploadAvatar from "../../../components/core/VeeValidateForm/Upload.vue";
import RolesComponent from "../../../components/RolesComponent.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import _ from "lodash";
import { adminPageTitle, hasRoles, roles } from "../../../utils/utils";
import router from "@/router";

const initialFormState = {
  name: "",
  email: "",
  password: "",
  avatar: "",
  status: true,
  roles: [],
};

export default {
  name: "AdminForm",
  components: {
    ValidationObserver,
    Input,
    SwitchInput,
    UploadAvatar,
    RolesComponent,
  },
  metaInfo: {
    title: adminPageTitle(labels.admins.title),
    titleTemplate: () => {
      return `${adminPageTitle()} - ${
        router.currentRoute.name.includes("edit")
          ? labels.admins.pageTitleEditing
          : labels.admins.pageTitleCreate
      }`;
    },
  },
  data() {
    return {
      labels: labels.admins,
      loading: false,
      submitting: false,
      form: _.cloneDeep(initialFormState),
    };
  },
  async created() {
    this.resetForm();
    if (this.$route.params.id) {
      await this.fetchData();
    }
    this.fetchRoles();
  },
  computed: {
    ...mapState("admins", ["admin"]),
    ...mapGetters({
      roles: "admins/getRoles",
    }),
    ...mapGetters("adminAuth", ["getLoggedUser"]),
    isEdit() {
      return !!this.$route.params?.id;
    },
    canEdit() {
      const { accessRoles } = this.getLoggedUser;
      return (
        !this.isEdit || hasRoles(accessRoles, [roles.admin.ROLE_ADMINS_UPDATE])
      );
    },
  },
  methods: {
    ...mapActions("admins", [
      "fetchRoles",
      "fetchAdmin",
      "createAdmin",
      "updateAdmin",
      "deleteAdmin",
      "resetForm",
    ]),
    async fetchData() {
      try {
        this.loading = true;
        await this.fetchAdmin(this.$route.params.id);
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
        this.$log.error(error);
        this.$router.push({ name: "admin.admins" });
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit(closeAfterSave = false) {
      if (this.submitting) return;
      this.submitting = true;
      let response = null;
      let editting = this.isEdit;
      try {
        if (editting) {
          response = await this.updateAdmin(this.form);
        } else {
          response = await this.createAdmin(this.form);
        }
        if (response) this.$message.success(response.message);
        if (closeAfterSave) {
          this.$router.push({ name: "admin.admins" });
        } else {
          if (!editting) {
            this.resetForm();
            this.$router.push({
              name: "admin.admins.edit",
              params: { id: response.data.id },
            });
          }
        }
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
      } finally {
        this.submitting = false;
      }
    },
    handleCancel() {
      this.$router.push({ name: "admin.admins" });
    },
    async onlyAlfa(value = "", field) {
      this.form[field] = await value.replace(/[^a-zA-Z\s]/g, "");
    },
  },
  watch: {
    "$route.params.id"(value, oldValue) {
      if (value && value !== oldValue) {
        this.fetchData();
      }
    },
    admin: {
      handler(admin = {}) {
        this.form = admin
          ? _.cloneDeep({ ...admin, status: !!admin?.status })
          : _.cloneDeep(initialFormState);
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.form = _.cloneDeep(initialFormState);
    this.resetForm();
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.admins-form {
  .form-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .form-icon {
    margin-right: 8px;
  }
  .form-button-group {
    margin-top: 16px;
  }
}
.ant-input[disabled],
.ant-time-picker-input[disabled],
.ant-input-number-disabled,
.ant-select-disabled .ant-select-selection {
  background-color: color(--white) !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
.ant-input[disabled],
.ant-time-picker-input[disabled],
.ant-input-number-disabled,
.ant-transfer-disabled .ant-transfer-list,
.ant-select-disabled .ant-select-selection {
  background-color: color(--white) !important;
  color: rgba(0, 0, 0, 0.65) !important;
  .ant-transfer-list-content-item-disabled {
    color: rgba(0, 0, 0, 0.65) !important;
  }
}
</style>
